import stylex from '@stylexjs/stylex';
export const styles = stylex.create({
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
    height: '700px',
    overflowX: 'auto',
    padding: '0 0 20px 20px',
  },

  input: {
    padding: '20px 0 20px 0',
  },

  sendeButtons: {
    paddingBottom: '20px',
  },
});
